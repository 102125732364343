import { Message } from 'element-ui';
// import AMap from 'vue-amap';
// import * as _filter from 'lodash/filter';
// import * as _isArray from 'lodash/isArray';
// import ElCol2 from '@/components/custom/Col2';
// import Select2 from '@/components/custom/Select2';
import store from '../store';
import { FormatDate, Calc, CurrentDistance, treeTailFun, excelDate } from './funs';
import { uoms } from './const';

function get422Msg(resp) {
  // return data.message + '\n' + (data.errors ? Object.values(data.errors).join('\n') : '');
  // TODO: 框架的message错误没有i18n
  let result = '执行指定操作，发生错误';
  if (resp.data) {
    result = (resp.data.errors ? Object.values(resp.data.errors).join('\n') : '');
    if (result === '' && resp.data.message) {
      result = resp.data.message;
    }
    if (result === '' && typeof resp.data === 'string') {
      result = resp.data;
    }
  } else if (resp.bodyText) {
    result = resp.bodyText;
  }
  return result;
}

function treeEach(arr, everyFun) {
  arr.forEach((item) => {
    if (item.children) {
      treeEach(item.children, everyFun);
      everyFun(item);
    }
  });
}

function analyseResponse(error) {
  if (typeof error !== 'object') {
    if (error === 'cancel') { // 应该是confirm的取消，什么都不用做
      return;
    }
    // eslint-disable-next-line
    // console.error(resp);
  }
  const resp = error.response;
  /* eslint-disable new-cap */
  if (resp.status === 400 || resp.status === 800) {
    Message({
      showClose: true,
      message: resp.data,
      type: 'warning',
      duration: 5000,
    });
  } else if (resp.status === 401) {
    Message({
      showClose: true,
      message: 'token 过期，请重新登陆',
      type: 'warning',
      duration: 5000,
    });
    store.commit('AUTH_LOGOUT');
  } else if (resp.status === 422) {
    Message({
      showClose: true,
      message: get422Msg(resp),
      type: 'warning',
      duration: 5000,
    });
  } else if (resp.status === 403) {
    Message({
      showClose: true,
      message: '无法执行指定操作，请确认您是否具有足够的权限。',
      // message: (resp.data && resp.data.message) ? resp.data.message : '无法执行指定操作，请确认您是否具有足够的权限。',
      type: 'warning',
      duration: 5000,
    });
  } else if (resp.status === 404) {
    Message({
      showClose: true,
      message: '无法获取指定数据，请确认您的操作。',
      type: 'warning',
      duration: 5000,
    });
  } else if (resp.status === 429) {
    Message({
      showClose: true,
      message: '您的操作过于频繁，请稍后再试。',
      type: 'warning',
      duration: 5000,
    });
  } else {
    Message({
      showClose: true,
      message: (resp.data && resp.data.message) ? resp.data.message : '操作有误或者服务器错误',
      type: resp.status >= 500 ? 'error' : 'warning',
      duration: 5000,
    });
  }
  if (resp.status === 418) {
    store.commit('AUTH_LOGOUT');
  }
}

let isRefreshing = false;
const requestArr = [];
function arrResolve() {
  while (requestArr.length > 0) {
    const pro = requestArr.shift();
    pro.resolve();
  }
}
function arrReject() {
  while (requestArr.length > 0) {
    const pro = requestArr.shift();
    pro.reject();
  }
}

/* 固定常量 */
// const TASK_ITEM_COLORS = {
//   normal: '#1fbfdb', // 蓝
//   // normal: '#00D8FF', // 蓝
//   urgent: '#E46651', // 橙红
//   linked: '#ffbf00', // 黄
//   // '#9b59b6', // 紫
//   // '#ff6384', // 粉
//   // '#41B883', // 绿
// };


export default {
  /* eslint-disable no-multi-assign */
  install(Vue) {
    // Vue.component('ElCol2', ElCol2);
    // Vue.component('Select2', Select2);

    Vue.mixin({
      methods: {
        emulateDocumentClick() {
          let evt;
          if (typeof window.Event === 'function') {
            evt = new window.Event('click');
          } else {
            evt = window.document.createEvent('HTMLEvents');
            evt.initEvent('click', true, true);
          }
          document.dispatchEvent(evt);
        },
      },
    });

    // 自动带上jwt token
    Vue.axios.defaults.baseURL = '/asset-api';
    Vue.axios.interceptors.request.use((config) => {
      if (store.state.auth.token) {
        config.headers.Authorization = store.state.auth.token;
      }
      if (store.state.auth.curCompanyType) {
        config.headers.companyType = store.state.auth.curCompanyType;
      }
      return config;
    }, err => Promise.reject(err));

    function refreshToken() {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          requestArr.push({ resolve, reject });
        });
      }
      isRefreshing = true;
      return Vue.axios.post('auth/refresh-token', {}).then((response) => {
        store.commit('AUTH_SET_TOKEN', response.data.token);
        isRefreshing = false;
        arrResolve();
      }, (refResponse) => {
        isRefreshing = false;
        arrReject(refResponse);
        store.commit('AUTH_LOGOUT'); // 刷新失败，退出登录，自动跳转到登录页面
        throw refResponse;
      });
    }

    const canRefresh = true;
    // 过期自动refresh token
    Vue.axios.interceptors.response.use(resp => resp, (error) => {
      let result = null;
      if (error.response.status === 401 && store.state.auth.token) {
        if (error.config.url.indexOf('refresh-token') === -1 && canRefresh) {
          result = refreshToken().then(() => {
            // error.config.baseURL = '';
            const r = Vue.axios.request(error.config);
            return r;
          }, (err) => {
            throw err;
          });
        }
        // Message({
        //   showClose: true,
        //   message: 'token 过期，请重新登陆',
        //   type: 'warning',
        //   duration: 5000,
        // });
        // store.commit('AUTH_LOGOUT');
      } else {
        throw error;
      }
      return result;
    });

    // filter格式化时间，传入的参数是 年月日时分秒 或者 时分秒，同时判断为空的情况
    // val 是时间,format是接收有没有参数然后选择格式化的形式

    // 传的参数是 年月日时分秒
    // 没有参数的时候，默认格式为'YYYY-MM-DD HH:MM' 年月日时分
    // 如果参数是‘d’,则格式为'YYYY-MM-DD' 年月日 (记忆：d代表到日，date)
    // 如果参数是‘m’，则格式为'MM-DD HH:MM' 月日时分(记忆：m代表到分)
    // 如果参数是‘s’,则格式为'hh: mm: ss'时分秒(记忆：s代表到秒)

    // 传的参数是 时分秒
    // 没有参数的时候，就是时分秒，默认格式为'hh: mm: ss'
    // 如果参数是‘m’，则格式为‘hh:mm’，时分（和上面的截止到时分参数一致的）
    Vue.filter('dateFilter', (val, format = '') => {
      // 先判断传过来的值是不是空的
      if (val === null || val === '' || val === undefined) {
        return '-';
      }
      // 判断是否是日期格式 YYYY-MM-DD HH:MM:SS(只要是数字，位数对了就行，并没有判断里面的)
      const test1 = /^\d{4}(-|\/)\d\d(-|\/)\d\d \d\d:\d\d:\d\d$/;
      // 判断是否是时分秒的日期格式 HH:MM:SS
      const test2 = /^\d\d:\d\d:\d\d$/;
      // 如果不是空的，判断传的值是不是个日期格式
      if (test1.test(val)) {
        if (format === 'd') {
          return val.substring(0, 10);
        } else if (format === 'm') {
          return val.substring(5, 16);
        } else if (format === 's') {
          return val.substring(11, 19);
        }
        return val.substring(0, 16);
      } else if (test2.test(val)) {
        if (format === 'm') {
          return val.substring(0, 5);
        }
        return val;
      }
      return '-';
    });

    Vue.filter('uomName', (val, type = 1) => {
      let result = uoms[val];
      if (!result) {
        return '';
      }
      let number = Math.floor(type);
      if (Number.isNaN(number) || number > 2) {
        number = 2;
      }
      if (number > 1) {
        result = `${val}(${result})`;
      }
      return result;
    });

    /**
     * 货币格式化过滤器
     * @param  {String} prefix 结果前缀如'&yen;', '$'等
     * @param  {Number} decimals 保留小数位数，默认2
     * @return {String} 千分位分隔符，如果不想有分隔符传''进来
     */
    Vue.filter('currency', (val, prefix = '', decimals = 2, $thousandsSep = ',') => {
      // isNaN(null) 也是false,所以前面把val可能的值都列一下
      if (val === null || Number.isNaN(Number(val))) {
        return '-';
      }
      // 如果decimals是一个数字，则金额保留小数位数
      let n = parseInt(decimals, 10);
      if (Number.isNaN(n)) {
        n = 2;
      }
      val = Calc.FixRound(Number(val), n, true);
      // 如果thousandsSep 为空，则不需要千位分隔符
      if ($thousandsSep === '') {
        return prefix + val;
      }
      // 默认是有千位分隔符的
      const num = val.split('.');
      // g是global全局搜索
      // 这个是replace的语法
      // str.replace(regexp|substr, newSubStr|function)
      // $n假如第一个参数是 RegExp对象，并且 n 是个小于100的非负整数，那么插入第 n 个括号匹配的字符串。
      num[0] = num[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'g'), '$1' + $thousandsSep);
      // num是由小数点前面和小数点后面分隔开的数组
      val = num.join('.');
      return prefix + val;
    });

    // 图片加载失败后的默认图片地址 v-err-src="'default.jpg'"
    Vue.directive('errSrc', {
      bind: (el, binding) => {
        el.addEventListener('error', () => {
          if (el.src !== binding.value) {
            el.src = binding.value;
          }
        });
      },
    });

    // Vue.directive('focus', {
    //   update: (el, binding) {
    //     el.focus();
    //   },
    // });

    // Message简化
    const message = (message, type = 'success', duration = 2000) => {
      Message({
        showClose: true,
        message,
        type,
        duration,
      });
    };

    // 判断当前用户是否有权限查看此项
    // perm|perm:staff_id_of_the_entity
    // Vue.directive('can', (el, binding) => {
    //   if (Vue.util.can(binding.value)) {
    //     el.style.display = '';
    //   } else {
    //     el.style.display = 'none';
    //   }
    // });

    // binary string to ArrayBuffer string;
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        // eslint-disable-next-line no-bitwise
        view[i] = s.charCodeAt(i) & 0xFF;
      }
      return buf;
    };

    Vue.calc = Vue.prototype.$calc = Calc;

    // 只支持到二级数组简单对象，其他更深层次的的建议用json
    const formDataAdd = (formData, obj) => {
      const keys = Object.keys(obj);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const item = obj[key];
        if (Array.isArray(item)) {
          for (let j = 0; j < item.length; j += 1) {
            const arrItem = item[j];
            if (arrItem && typeof arrItem === 'object') {
              const subKeys = Object.keys(arrItem);
              for (let k = 0; k < subKeys.length; k += 1) {
                formData.append(
                  `${key}[${j}][${subKeys[k]}]`,
                  arrItem[subKeys[k]]
                );
              }
            } else if (arrItem !== null) {
              if (key.indexOf('[]') === -1) {
                formData.append(`${key}[]`, arrItem);
              } else {
                formData.append(key, arrItem);
              }
            }
          }
        } else if (item && typeof item === 'object') {
          const subKeys = Object.keys(item);
          for (let k = 0; k < subKeys.length; k += 1) {
            if (item[subKeys[k]]) {
              formData.append(
                `${key}[${subKeys[k]}]`,
                item[subKeys[k]]
              );
            }
          }
        } else if (item !== null) {
          formData.append(key, item);
        }
      }
      return formData;
    };

    Vue.utils = Vue.prototype.$utils = {
      formDataAdd,
      treeTailFun,
      treeEach,
      FormatDate,
      excelDate,
      s2ab,
      formatDate: FormatDate,
      can(value) {
        // 主管理员可以做任何事
        // if (_filter(store.state.auth.user.current_roles, { type: 1 }).length >= 1) {
        //   return true;
        // }
        if (store.state.auth.user.type === 'admin') {
          return true;
        }
        if (!store.state.auth.user || !store.state.auth.user.allPerms) {
          return false;
        }
        let pass = false;
        value.split('|').forEach((v) => {
          if (pass) { // break forEach is impossible
            return;
          }
          pass = store.state.auth.user.allPerms.indexOf(v) > -1;
          // const [perm, sid] = v.split(':');
          // if (sid === undefined) {
          //   pass = store.state.auth.user.current_permissions.indexOf(perm) > -1;
          // } else {
          //   pass = store.state.auth.user.current_permissions.indexOf(perm) > -1 &&
          //     store.state.auth.user.current_staff.id === parseInt(sid, 10);
          // }
        });
        return pass;
      },
      notCan(value) {
        let pass = true;
        value.split('|').forEach((v) => {
          if (store.state.auth.user.allPerms.indexOf(v) > -1) {
            pass = false;
          }
        });
        return pass;
      },
      msg: message,
      // 请求错误提示
      resp(resp) {
        analyseResponse(resp);
      },
      filter(list, fun) {
        const arr = list.filter(fun);
        let result = false;
        if (arr.length > 0) {
          result = arr[0];
        }
        return result;
      },
      curTimeDistance(time) {
        return CurrentDistance(time);
      },
      // 比较当前时间和一个'xxxx-xx-xx'时间
      // 若当前时间超过被比较时间返回false, 否则返回true,
      compareDate(date, today = new Date()) {
        today = new Date(today);
        today.setHours(0, 0, 0, 0);
        date = new Date(date);
        date.setHours(0, 0, 0, 0);
        if (today > date) {
          return false;
        }
        return true;
      },
      dateFormat(dateObj = null, fmt = 'Y-m-d') {
        const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
        const months = [
          '一月', '二月', '三月', '四月', '五月', '六月',
          '七月', '八月', '九月', '十月', '十一月', '十二月',
        ];

        const lz = (num) => {
          num = '' + num;
          if (num.length === 1) {
            return '0' + num;
          }
          return num;
        };

        if (dateObj === null) {
          dateObj = new Date();
        }
        const t = typeof dateObj === 'number' ? new Date(dateObj * 1000) : dateObj;
        if (!(t instanceof Date)) {
          throw new Error('被处理的只能是Date对象');
        }
        const date = t.getDate();
        const year = t.getFullYear();
        const hours = t.getHours();
        const day = t.getDay();
        const dayName = days[day];
        const min = t.getMinutes();
        const sec = t.getSeconds();
        const month = t.getMonth();
        const am = hours < 12 ? 'am' : 'pm';
        const ms = t.getTime() % 1000;
        let hours12 = hours % 12;
        hours12 = hours12 || 12;

        const fd = new Date(t.getFullYear(), 0, 1);
        const dayOfYear = Math.round((t - fd) / 8.64e7);
        const weekNum = Math.ceil((((t - fd) / 8.64e7) + fd.getDay() - 1) / 7);

        const a = {
          d: lz(date),
          D: dayName.substr(0, 3),
          l: dayName,
          w: day,
          j: date,
          z: dayOfYear,
          W: weekNum,
          M: months[month].substr(0, 3),
          F: months[month],
          Y: year,
          a: am,
          A: am.toUpperCase(),
          y: ('' + year).substr(2, 2),
          c: t.toISOString(),
          m: lz(month + 1),
          U: Math.round(t / 1000),
          g: hours12,
          G: lz(hours12),
          h: hours,
          H: lz(hours),
          i: lz(min),
          s: lz(sec),
          e: ms,
        };

        const v = [];
        Object.keys(a).forEach((k) => {
          v.push('(\\\\)?' + k);
        });

        return fmt.replace(new RegExp(v.join('|'), 'g'), (k) => {
          if (a[k]) {
            return a[k];
          }
          return k.replace('\\', '');
        });
      },
      // 初始化高德地图
      // initAMap() {
      //   if (!window.AMap) {
      //     AMap.initAMapApiLoader({
      //       key: '872af21f58f148b140f3b14e9b36dace',
      //       plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView',
      //         'AMap.ToolBar', 'MarkerClusterer'],
      //       uiVersion: '1.0',
      //       v: '1.4.3',
      //     });
      //   }
      // },
    };
  },
};
